<template>
 <div class="overall">
    <div class="content">
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form label-width="90px">
            <el-form-item label="工作类型">
              <el-select v-model.trim="form.jobType" placeholder="请选择">
                <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="seach()">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>

       <!-- table -->
       <div class="tableBox">
            <el-table
                :data="tableData"
                v-loading="tableLoading"
                max-height="650"
                border
                ref="multipleTable"
                style="width: 100%">
                <!-- <el-table-column
                type="selection"
                width="55">
                </el-table-column> -->
                <el-table-column
                    prop="createAt" 
                    label="创建时间"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="name" 
                    label="骑手">
                </el-table-column>
                <el-table-column
                    prop="phone" 
                    label="手机号">
                </el-table-column>
                <el-table-column
                    prop="idcard" 
                    label="身份证号">
                    <template slot-scope="scope">{{ scope.row.idcard? scope.row.idcard.replace(/^(.{6})(?:\w+)(.{4})$/,"\$1********\$2"):""}}</template>
                </el-table-column>
                <el-table-column
                    prop="siteName" 
                    label="门店">
                </el-table-column>
                <el-table-column
                    prop="address" 
                    label="地址">
                </el-table-column>
                <el-table-column
                    prop="url" 
                    label="保险">
                </el-table-column>
                <el-table-column
                    prop="number" 
                    label="保单号">
                </el-table-column>
                <el-table-column
                    prop="startTime" 
                    label="保单生效时间">
                </el-table-column>
                <el-table-column
                    prop="endTime" 
                    label="保单结束时间">
                </el-table-column>
                <el-table-column
                    prop="createName" 
                    label="操作人">
                </el-table-column>
            </el-table>
            
        </div>
  <!-- 分页 -->
  <div class="paginationBox">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          background
          :total="total">
        </el-pagination>
      </div>
 </div>
</div>
</template>

<script>
import {getList} from '../../../api/remind';
export default {
  data(){
    return{
      option:[
          {value:1,label:'兼职'},
          {value:2,label:'全职'}
      ],
      form:{
        jobType:''
      },
      currentPage:1,
      pageSize:10,
      total:0,
      tableData: [],
      tableLoading: true,
    }
  },
  mounted(){
    debugger
    //下拉默认是1：兼职
  this.form.jobType=this.option[0].value;
  this.remindList();
  },
  methods:{
    async remindList(){
      try {
        const params = {
          page:this.currentPage,
          limit:this.pageSize
        }
        let values ={
          ...params,
          ...this.form
        }
        this.tableLoading = true;
        let data = await getList(values);
        this.total = data.total;
        this.tableData = data.insuranceList;
      } catch (error) {
        console.log("error",error)
      }
      this.tableLoading = false;
    },
      //搜索
    seach() {
      this.remindList();
    },
    // 重置
    reset() {
        //下拉默认是待审核
      this.form.jobType=this.option[0].value;
      this.remindList();
    },
    //复选框
    handleSelectionChange(selectionList) {
          this.ids = [];
          selectionList.forEach(ele => {
          this.ids.push(ele.id);
      });
      return this.ids.join(",");
    },
    //更改一页的条数
    handleSizeChange(size) {
        this.pageSize = size;
        //重新走页面初始化方法
        this.QuitList();
    },
    //更改页数
    handleCurrentChange(current) {
        this.currentPage = current;
        //重新走页面初始化方法
        this.QuitList();
    },
  }
}
</script>

<style lang="scss" scoped>
.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>